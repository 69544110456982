.sider {
	position: relative;
	z-index: 5;
	border-right: 1px solid #f0f0f0;
	.logo-wrapper {
		padding:30px;
		border-bottom: 1px solid #f0f0f0;

		img {
			max-width: 100%;
			height: auto;

		}
	}
}