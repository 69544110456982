//
// SWAL
//

.swal2-title {
	color: $c1 !important;
}

.swal2-confirm {
	background: $c2 !important;
}

//
// Loading Page
//


.loading-page-view {
	height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;

	svg path {
		fill: $c2;
	}
}

//
// Headinhs
//

h1.ant-typography {
	color: $c2 !important;
}



//
// Page title
//

.page-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}



//
// users not created
//

.users-not-created {
	background: rgba(#ec3835, .05);
	margin-top: 30px;
	padding: 20px;
	border: 1px solid rgba(#ec3835, .1);

	h4 {
		color: #ec3835;
		font-size: 1rem;
	}
}



.select-min-option {
	float: right;
	color: #888;
}

.full-tabs {
	display: flex !important;

	.ant-radio-button-wrapper {
		flex-grow: 1;

		> span {
			width: 100% !important;
			display: block;
			text-align: center !important;
		}
	}
}


.full-upload {
	width: 100%;
	display: block;

	.ant-upload {
		width: 100%;
	}
}


// .ant-tabs-tab.ant-tabs-tab-active {
// 	border-top: 1px solid $c1 !important;
// }

.message-popover {
	max-width: 500px;
}

.ticket-conversation {
	height: 100%;
	display: flex;
	flex-direction: column;

	&-messages {
		flex: 1;
		overflow: auto;

		.empty,
		.loading {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
	}

	&-textarea {
		padding-top: 20px;
		border-top: 1px solid #e5e5e5;
	}
}

.ticket-conversation {
	.ant-comment {
		// .ant-comment-inner {
		// 	padding: 0;
		// }
		margin-bottom: 15px;

		.ant-comment-inner {
			background: #f9f9f9;
			border: 2px solid #f5f5f5;
			padding: 15px;
			border-radius: 3px !important;

			.ant-comment-content-author-name {
				div {
					display: flex;

					.badge {
						margin-left: 10px;
						padding: 1px 5px;
						vertical-align: middle;
						border-radius: 10px;
						position: relative;
						margin-top: -2px;

						&.partner,
						&.partner_admin {
							background: #fb822a;
							color: white;
						}

						&.user {
							background: #41d154;
							color: white;
						}
					}
				}
			}
		}

		&.me {
			.ant-comment-inner {
				background: rgb($c1, 0.05);
				border: 2px solid rgba($c1, 0.15);
				color: $c1;

				.ant-comment-content-author-name {
					div {
						color: $c1 !important;
						font-weight: 500;
					}
				}
			}
		}
	}
}

hr {
	border: 0;
	border-top: 1px solid #e5e5e5;
}


.rate-item {
	display: flex;
	gap: 2rem;

	.rate-view {
		.anticon-star {
			color: #fec839;
		}
		.outline {
			color: #e5e5e5;
		}
	}

	.rate-description {
		flex: 1;

		em {
			color: $text;
			font-style: normal;
			display: block;
			font-size: .8rem;
			margin-top: 10px;
		}
	}
}


.ctn-list-wrapper {
	.ctn-list {
		height: 400px;
		overflow: auto;
		border: 1px solid #e5e5e5;
		padding: 20px;
		display: flex;
		flex-direction: column;

		.check-item {
			margin-bottom: 8px;
			border-bottom: 1px solid #e5e5e5;
			padding-bottom: 8px;
		}
	}

	.ctn-list-search {
		background: #f9f9f9;
		padding: 10px;
		border: 1px solid #e5e5e5;
		border-bottom: 0;
	}
}

// .ant-checkbox-group.vertical-group {
// 	flex-direction: column;
// 	display: flex;
// }