body[data-theme=dark] {

	$c1: $c1_light;

	$bg: #191919;
	$border: #424242;

	.main-wrapper .site-layout-background {
		background: $bg;
	}

	.ant-layout-sider {
		background: #111;
		border-color: $border;
	}

	.sider .logo-wrapper,
	.header-inset-view .search-view {
		border-color: $border;
	}

	.ant-layout-header {
		background: none;
		border-bottom: 1px solid $border;
	}

	.header-inset-view .user-info {
		color: $c1;
	}


	.ant-modal-header,
	 {
		background: $bg;
	}

	.ant-modal-mask {
		background: rgba(black, .9);
	}
}